import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecuredMaterialModule } from '../secured-material/secured-material.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SecuredTokenInterceptor } from 'src/app/core/interceptor/secured-token.interceptor';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalService
} from '@azure/msal-angular';
import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { FooterComponent } from './footer/footer.component';
import { LoggedSkeletonComponent } from './logged-skeleton/logged-skeleton.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SelectSearchComponent } from './select-search/select-search.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { LastDateSyncComponent } from './last-date-sync/last-date-sync.component';
import { RangeTimePickerComponent } from './range-time-picker/range-time-picker.component';
import { MultipleSelectSearchComponent } from './multiple-select-search/multiple-select-search.component';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';
import { DurationFormatPipe } from 'src/app/pipes/duration-format.pipe';
import { AddButtonComponent } from './add-button/add-button.component';
import { ScreenContainerComponent } from './screen-container/screen-container.component';
import { OutlinedIconComponent } from './outlined-icon/outlined-icon.component';
import { ChannelsHeaderComponent } from 'src/app/features/secured-skeleton/channels/channels-header/channels-header.component';
import { ChannelsTableComponent } from 'src/app/features/secured-skeleton/channels/channels-table/channels-table.component';
import { DigitOnlyDirective } from 'src/app/core/directive/digits-only-directive';
import { AutoFocusDirective } from 'src/app/core/directive/auto-focus-directive';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { LoadingScreenComponent } from '../../core/components/loading-screen/loading-screen.component';
import { MatChipsModule } from '@angular/material/chips';
import { DialogModule } from './dialog/dialog.module';
import { MenuComponent } from './menu/menu.component';
import { ContentTagsFormFieldComponent } from 'src/app/features/secured-skeleton/content-playlist/content-playlist-dialog/media-library/content-tags-form-field/content-tags-form-field.component';
import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';
import { InputStatesReflectorDirective } from './input-states-reflector.directive';
import { CountFormatPipe } from '../../pipes/count-format.pipe';
import { IfFeatureEnabledDirective } from 'src/app/core/directive/if-feature-enabled.directive';
import { IfFeatureDisabledDirective } from 'src/app/core/directive/if-feature-disabled.directive';
import { HeaderSearchComponent } from './header-search/header-search.component';
import { CustomSidePanelComponent } from './custom-side-panel/custom-side-panel.component';
import { CustomExpansionSelectComponent } from './custom-expansion-select/custom-expansion-select.component';
import { FreeDraggingDirective } from '../../core/directive/free-dragging.directive';
import { InsideViewportDirective } from '../../core/directive/inside-viewport.directive';
import { FreeDraggingHandleDirective } from '../../core/directive/free-dragging-handle.directive';
import { FreeDraggingComponent } from './free-dragging/free-dragging.component';
/** Import all data shared by 'secured' modules here */
@NgModule({
  declarations: [
    DateFormatPipe,
    CountFormatPipe,
    DurationFormatPipe,
    HeaderComponent,
    IfFeatureEnabledDirective,
    IfFeatureDisabledDirective,
    SidenavComponent,
    FooterComponent,
    LoggedSkeletonComponent,
    SelectSearchComponent,
    LoadingIndicatorComponent,
    MessageDialogComponent,
    AddButtonComponent,
    LastDateSyncComponent,
    RangeTimePickerComponent,
    MultipleSelectSearchComponent,
    AddButtonComponent,
    ScreenContainerComponent,
    OutlinedIconComponent,
    ChannelsHeaderComponent,
    ChannelsTableComponent,
    DigitOnlyDirective,
    AutoFocusDirective,
    LoadingScreenComponent,
    MenuComponent,
    ContentTagsFormFieldComponent,
    CustomSnackbarComponent,
    InputStatesReflectorDirective,
    HeaderSearchComponent,
    CustomSidePanelComponent,
    CustomExpansionSelectComponent,
    FreeDraggingDirective,
    FreeDraggingHandleDirective,
    FreeDraggingComponent,
    InsideViewportDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    SecuredMaterialModule,
    MatMenuModule,
    MatProgressBarModule,
    MatChipsModule,
    OverlayModule,
    DialogModule
  ],
  exports: [
    MenuComponent,
    HeaderSearchComponent,
    IfFeatureDisabledDirective,
    IfFeatureEnabledDirective,
    InputStatesReflectorDirective,
    DateFormatPipe,
    CountFormatPipe,
    DurationFormatPipe,
    SelectSearchComponent,
    LoadingIndicatorComponent,
    MessageDialogComponent,
    AddButtonComponent,
    ScreenContainerComponent,
    DigitOnlyDirective,
    AutoFocusDirective,
    LastDateSyncComponent,
    FooterComponent,
    HeaderComponent,
    OutlinedIconComponent,
    RangeTimePickerComponent,
    MultipleSelectSearchComponent,
    ChannelsHeaderComponent,
    ChannelsTableComponent,
    ContentTagsFormFieldComponent,
    CustomSnackbarComponent,
    CustomSidePanelComponent,
    CustomExpansionSelectComponent,
    FreeDraggingDirective,
    FreeDraggingHandleDirective,
    FreeDraggingComponent,
    InsideViewportDirective,
  ],
  providers: [
    MsalBroadcastService,
    MsalGuard,
    MsalService,
    {
      provide: MAT_TABS_CONFIG,
      useValue: { animationDuration: '500ms' }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecuredTokenInterceptor,
      multi: true
    }
  ]
})
export class SecuredSharedModule {}
